<!-- eslint-disable -->
<template>
    <v-container color="secondary" fluid class="">
        <v-data-iterator light :items="items" :items-per-page.sync="itemsPerPage" :page.sync="page" :search="search"
            :custom-filter="itensfiltrados" :sort-by="sortBy.toLowerCase()" :sort-desc="sortDesc" hide-default-footer>
            <template v-slot:header>
                <!-- Filtro Tabela Desktop -->
                <v-toolbar v-if="$vuetify.breakpoint.mdAndUp" rounded solo-inverted color="primary" class="mb-1">
                    <v-text-field dark background-color="primary" color="white" solo v-model="search" clearable
                        hide-details prepend-inner-icon="mdi-magnify" label="Busque seu curso">
                    </v-text-field>

                    <template>
                        <v-spacer></v-spacer>

                        <v-select dark color="white" background-color="primary" solo item-color="white" outlined
                            v-model="sortBy" hide-details :items="keys" prefix="Formação: "
                            prepend-inner-icon="mdi-school" label="Formação"></v-select>

                        <v-spacer></v-spacer>

                    </template>

                </v-toolbar>

                <!-- Filtro Tabela Mobile -->
                <v-toolbar min-height="140px" v-if="$vuetify.breakpoint.smAndDown" rounded solo-inverted color="primary"
                    class="c-filtro-table ma-0 pa-0">

                    <v-col cols="12" sm="12" class="ma-0 pa-0 mt-3 mb-n16">
                        <v-select dark color="white" background-color="primary" solo item-color="white" outlined
                            v-model="sortBy" hide-details :items="keys" prefix="Formação: "
                            prepend-inner-icon="mdi-school" label="Formação" class="my-4">
                        </v-select>

                        <v-text-field dark background-color="primary" color="white" solo v-model="search" clearable
                            hide-details prepend-inner-icon="mdi-magnify" label="Busque seu curso" class="my-4">
                        </v-text-field>

                    </v-col>
                </v-toolbar>
            </template>

            <template v-slot:default="props">
                <v-row>
                    <v-col v-for="(item, idxPrincipal) in props.items" :key="idxPrincipal" cols="12" sm="12" md="12"
                        lg="12" class="pa-4">
                        <v-card color="transparent">
                            <v-card-title class="c-table-titulo subheading font-weight-bold ma-0 pa-0">

                                <!-- Imagem de destaque tabela -->
                                <v-img src="@/assets/box/faculdade-em-itaborai-faces.webp"
                                    lazy-src="@/assets/box/draft/faculdade-em-itaborai-faces-draft.webp"
                                    min-height="110px" height="110px" cover
                                    :position="$vuetify.breakpoint.xsOnly ? 'center' : 'top'" fetchpriority="high"
                                    class="ma-0 pa-0 c-img-table">
                                    <h3 class=" text-h4 primary--text pa-5">{{ item.formacao }}</h3>
                                </v-img>
                            </v-card-title>

                            <v-divider></v-divider>

                            <v-list dense class="c-lista">
                                <!-- Lista da Formação Setada -->
                                <v-list-item v-for="(key, index) in cursofiltrado(item.cursos)" :key="index">

                                    <v-list-item-content v-if="item.formacao === sortBy || sortBy == 'Todos os Cursos'"
                                        class="align-end c-lista-curso">

                                        <v-list-item-title>{{ item.formacao }}</v-list-item-title>

                                        <h4 class="pa-2 pb-2 text-uppercase secondary--text">{{ key }}</h4>

                                        <p class="pt-0">
                                            <v-btn @click="sendMsmInscricaoTable(key)" x-small rounded
                                                class="c-btn-grandiente primary--text">Orientação<v-icon
                                                    x-small>mdi-whatsapp</v-icon></v-btn>

                                            <v-btn @click="redirecionarMaisInfo(item.formacao, key)" x-small rounded
                                                class="c-btn-grandiente primary--text">+
                                                INFORMAÇÕES<v-icon x-small>mdi-information-outline</v-icon>
                                            </v-btn>
                                        </p>
                                    </v-list-item-content>

                                </v-list-item>
                                <!-- Lista da formação não setada -->
                                <v-list-item v-for="(key_2, index_2) in cursosEncontrados" :key="key_2.curso">
                                    <v-list-item-content v-if="cursosEncontrados.curso.formacao !== sortBy"
                                        class="align-end c-lista-curso">
                                        <v-list-item-title>Formação: {{ key_2.formacao }}</v-list-item-title>
                                        <h4 class="pa-2 pb-2 text-uppercase secondary--text">{{ key_2.curso }}</h4>
                                        <p class="pt-0">
                                            <v-btn @click="sendMsmInscricaoTable(key_2.curso)" x-small rounded
                                                class="c-btn-grandiente primary--text">Orientação<v-icon
                                                    x-small>mdi-whatsapp</v-icon></v-btn>

                                            <v-btn @click="redirecionarMaisInfo(key_2.formacao, key_2.curso)" x-small
                                                rounded class="c-btn-grandiente primary--text">+
                                                INFORMAÇÕES<v-icon x-small>mdi-information-outline</v-icon></v-btn>
                                        </p>
                                    </v-list-item-content>
                                </v-list-item>

                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </v-data-iterator>
    </v-container>
</template>
  
<script>
export default {
    name: "TabelaCursos",
    props: {
        whatsAppTable: String,
        linkBox: String
    },
    components: {},
    data: () => ({
        msmInscricaoTable: '',
        search: '',
        filter: {},
        cursosEncontrados: {},
        sortDesc: false,
        page: 1,
        itemsPerPage: 10,
        sortBy: 'Graduação',
        keys: [
            'Graduação',
            'Pós-Graduação',
            'Cursos Técnicos',
            'Capacitação Profissional',
            'Especialização Técnicas - Pós Técnico',
            'Cursos Avançados em Teologia',
            'Cursos Preparatórios',
            'Todos os Cursos'
        ],
        items: [
            {
                formacao: 'Graduação',
                cursos: ['Administração',
                    'Gestão Ambiental',
                    'Marketing',
                    'Análise e Desenvolvimento de Sistemas',
                    'Gestão Comercial',
                    'Ministério Pastoral',
                    'Biomedicina',
                    'Gestão de Recursos Humanos',
                    'Pedagogia',
                    'Educação Física',
                    'Gestão do Agronegócio',
                    'Processos Gerencias',
                    'Enfermagem',
                    'Gestão Financeira',
                    'Radiologia',
                    'Engenharia Elétrica',
                    'Gestão Pública',
                    'Serviço Social',
                    'Estética e Cosmética',
                    'Letras inglês – Português',
                    'Serviços Jurídicos',
                    'Formação em Homeschooling',
                    'Logística',
                    'Teologia Presencial',
                    'Gastronomia',
                    'Marketing Digital',
                    'Teologia EAD '],
                pesquisa: true
            },
            {
                formacao: 'Pós-Graduação',
                cursos: ['Urgência E Emergência', 'Economia Finanças Digitais E Mercados', 'Teologia E Esfera Pública', 'Uti Neonatal', 'Inteligência Emocional No Serviço Público', 'Escritos Paulinos', 'Atenção Primária E Saúde Coletiva', 'MBA Em Gestão De Projetos', 'Antigo E Novo Testamento', 'Centro Cirúrgico E Central De Materiais', 'MBA Em Gestão Pública', 'Teologia (Pentecostalismo E Neopentecostalismo)', 'MBA De Gestão Hospitalar E Serviços De Saúde Pública E Privada', 'Vigilância Em Saúde Ambiental', 'Filosofia, Sociologia E Cultura', 'Práticas Integrativas E Complementares Em Saúde', 'Gestão E Auditora Ambiental', 'Ciência Da Religião Com Ênfase Em Ensino Religioso', 'Gestão Escolar E Inteligência Emocional – Integração Socioemocional', 'Segurança Do Trabalho Biossegurança Em Saúde', 'Saúde E Espiritualidade', 'Educação Inclusiva E Neuropsicopedagogia', 'Teologia Sistemático-Pastoral', 'Arte da Pregação e Estudos Bíblicos'],
                pesquisa: true
            },
            {
                formacao: 'Cursos Técnicos',
                cursos: ['Administração', 'Secretaria ESCOLAR', 'Informática (NOVO)', 'Aquicultura', 'Podologia', 'Mecatrônia (NOVO)', 'Enfermagem', 'Análises Clínicas (NOVO)', 'Eletroeletrônica (NOVO)', 'Estética', 'Farmácia (NOVO)', 'Automação INDUSTRIAL (NOVO)', 'Radiologa'],
                pesquisa: true
            },
            {
                formacao: 'Capacitação Profissional',
                cursos: ['Curso Prático Em Podologia', 'Mamografia (+)', 'Densitometria Óssea', 'Administração De Medicamentos Punção Venosa', 'Massagem Modeladora', 'Ressonância Magnética', 'Formação Em Missiologia E Plantação De Igrejas', 'Drenagem Linfática', 'Cuidador De Idosos', 'Psicanálise Clínica', 'Tomografia Computadorizada', 'Atendimento E Reabilitação De Dependentes Químicos'],
                pesquisa: true
            },
            {
                formacao: 'Especialização Técnicas - Pós Técnico',
                cursos: ['Instrumentação Cirúrgica', 'Rede De Computadores', 'Programação De Jogos Digitais E Web', 'Neonatal', 'Robótica Industrial', 'Desenvolvimento De Sistemas', 'Enfermagem Do Trabalho', 'Máquinas Navais', 'Banco De Dados', 'Urgência E Emergência'],
                pesquisa: true
            },
            {
                formacao: 'Cursos Avançados em Teologia',
                cursos: ["Teologia Para Jovens (Tt Teologia Teen)", "Escatologia", "História Da Igreja", "Teologia Bíblica", "Escritos Paulinos", "Teologia Contemporânea", "Ciências Das Religiões", "Antigo E Novo Testamento", "Teologia Sistemática", "História Da Igreja", "História De Israel"],
                pesquisa: true
            },
            {
                formacao: 'Cursos Preparatórios',
                cursos: ["Português Para Concursos", "Direito Para Concursos", "Pré-Enem 2023", "Redação Para Concursos", "Preparatório Para Polícia Militar", "Matemática Para Concursos Financeira Rac. Lógico)"],
                pesquisa: true
            },
        ],
        linkCursos: [
            { formacao: 'Graduação', curso: 'Administração', link: 'https://facesbr.com/curso/administracao-ead' },
            { formacao: 'Graduação', curso: 'Gestão Ambiental', link: 'https://facesbr.com/curso/gestao-ambiental-ead' },
            { formacao: 'Graduação', curso: 'Marketing', link: 'https://facesbr.com/curso/marketing' },
            { formacao: 'Graduação', curso: 'Análise e Desenvolvimento de Sistemas', link: 'https://facesbr.com/curso/analise-e-desenvolvimento-de-sistemas' },
            { formacao: 'Graduação', curso: 'Gestão Comercial', link: 'https://facesbr.com/curso/gestao-comercial-ead' },
            { formacao: 'Graduação', curso: 'Ministério Pastoral', link: 'https://facesbr.com/curso/ministerio-pastoral' },
            { formacao: 'Graduação', curso: 'Biomedicina', link: 'Sem Link' },
            { formacao: 'Graduação', curso: 'Gestão de Recursos Humanos', link: 'Sem Link' },
            { formacao: 'Graduação', curso: 'Pedagogia', link: 'https://facesbr.com/curso/pedagogia' },
            { formacao: 'Graduação', curso: 'Educação Física', link: 'Sem Link' },
            { formacao: 'Graduação', curso: 'Gestão do Agronegócio', link: 'Sem Link' },
            { formacao: 'Graduação', curso: 'Processos Gerencias', link: 'https://facesbr.com/curso/processos-gerenciais' },
            { formacao: 'Graduação', curso: 'Enfermagem', link: 'Sem Link' },
            { formacao: 'Graduação', curso: 'Gestão Financeira', link: 'https://facesbr.com/curso/gestao-financeira-ead' },
            { formacao: 'Graduação', curso: 'Radiologia', link: 'Sem Link' },
            { formacao: 'Graduação', curso: 'Engenharia Elétrica', link: 'Sem Link' },
            { formacao: 'Graduação', curso: 'Gestão Pública', link: 'Sem Link' },
            { formacao: 'Graduação', curso: 'Serviço Social', link: 'https://facesbr.com/curso/servico-social' },
            { formacao: 'Graduação', curso: 'Estética e Cosmética', link: 'Sem Link' },
            { formacao: 'Graduação', curso: 'Letras inglês – Português', link: 'https://facesbr.com/curso/letras-ingles' },
            { formacao: 'Graduação', curso: 'Serviços Jurídicos', link: 'https://facesbr.com/curso/servicos-juridicos' },
            { formacao: 'Graduação', curso: 'Formação em Homeschooling', link: 'Sem Link' },
            { formacao: 'Graduação', curso: 'Logística', link: 'https://facesbr.com/curso/logistica' },
            { formacao: 'Graduação', curso: 'Teologia Presencial', link: 'Sem Link' },
            { formacao: 'Graduação', curso: 'Gastronomia', link: 'Sem Link' },
            { formacao: 'Graduação', curso: 'Marketing Digital', link: 'Sem Link' },
            { formacao: 'Graduação', curso: 'Teologia EAD', link: 'https://facesbr.com/teologia' },
            { formacao: 'Pós-Graduação', curso: 'Urgência E Emergência', link: 'Sem Link' },
            { formacao: 'Pós-Graduação', curso: 'Economia Finanças Digitais E Mercados', link: 'Sem Link' },
            { formacao: 'Pós-Graduação', curso: 'Teologia E Esfera Pública', link: 'Sem Link' },
            { formacao: 'Pós-Graduação', curso: 'Uti Neonatal', link: 'Sem Link' },
            { formacao: 'Pós-Graduação', curso: 'Inteligência Emocional No Serviço Público', link: 'Sem Link' },
            { formacao: 'Pós-Graduação', curso: 'Escritos Paulinos', link: 'Sem Link' },
            { formacao: 'Pós-Graduação', curso: 'Atenção Primária E Saúde Coletiva', link: 'Sem Link' },
            { formacao: 'Pós-Graduação', curso: 'MBA Em Gestão De Projetos', link: 'Sem Link' },
            { formacao: 'Pós-Graduação', curso: 'Antigo E Novo Testamento', link: 'Sem Link' },
            { formacao: 'Pós-Graduação', curso: 'Centro Cirúrgico E Central De Materiais', link: 'Sem Link' },
            { formacao: 'Pós-Graduação', curso: 'MBA Em Gestão Pública', link: 'Sem Link' },
            { formacao: 'Pós-Graduação', curso: 'Teologia (Pentecostalismo E Neopentecostalismo)', link: 'Sem Link' },
            { formacao: 'Pós-Graduação', curso: 'MBA De Gestão Hospitalar E Serviços De Saúde Pública E Privada', link: 'Sem Link' },
            { formacao: 'Pós-Graduação', curso: 'Vigilância Em Saúde Ambiental', link: 'https://facesbr.com/produto/pos-graduacao-em-vigilancia-em-saude-ambiental' },
            { formacao: 'Pós-Graduação', curso: 'Filosofia', link: 'Sem Link' },
            { formacao: 'Pós-Graduação', curso: 'Sociologia E Cultura', link: 'Sem Link' },
            { formacao: 'Pós-Graduação', curso: 'Práticas Integrativas E Complementares Em Saúde', link: 'Sem Link' },
            { formacao: 'Pós-Graduação', curso: 'Gestão E Auditora Ambiental', link: 'Sem Link' },
            { formacao: 'Pós-Graduação', curso: 'Ciência Da Religião Com Ênfase Em Ensino Religioso', link: 'https://facesbr.com/produto/pos-graduacao-em-ciencia-da-religiao-com-enfase-em-ensino-religioso' },
            { formacao: 'Pós-Graduação', curso: 'Gestão Escolar E Inteligência Emocional – Integração Socioemocional', link: 'Sem Link' },
            { formacao: 'Pós-Graduação', curso: 'Segurança Do Trabalho Biossegurança Em Saúde', link: 'https://facesbr.com/produto/pos-graduacao-em-biosseguranca-em-servico-de-saude-seguranca-do-trabalho-e-meio-ambiente' },
            { formacao: 'Pós-Graduação', curso: 'E Meio Ambiente', link: 'Sem Link' },
            { formacao: 'Pós-Graduação', curso: 'Saúde E Espiritualidade', link: 'https://facesbr.com/produto/pos-graduacao-em-saude-e-espiritualidade' },
            { formacao: 'Pós-Graduação', curso: 'Educação Inclusiva E Neuropsicopedagogia', link: 'https://facesbr.com/produto/pos-graduacao-em-educacao-especial-e-inclusiva' },
            { formacao: 'Pós-Graduação', curso: 'Teologia Sistemático-Pastoral', link: 'Sem Link' },
            { formacao: 'Pós-Graduação', curso: 'Arte da Pregação e Estudos Bíblicos', link: 'https://facesbr.com/curso/arte-da-pregacao-e-estudos-biblicos' },
            { formacao: 'Cursos Avançados em Teologia', curso: 'Teologia Para Jovens (Tt Teologia Teen)', link: 'Sem Link' },
            { formacao: 'Cursos Avançados em Teologia', curso: 'Escatologia', link: 'Sem Link' },
            { formacao: 'Cursos Avançados em Teologia', curso: 'História Da Igreja', link: 'Sem Link' },
            { formacao: 'Cursos Avançados em Teologia', curso: 'Teologia Bíblica', link: 'Sem Link' },
            { formacao: 'Cursos Avançados em Teologia', curso: 'Escritos Paulinos', link: 'Sem Link' },
            { formacao: 'Cursos Avançados em Teologia', curso: 'Teologia Contemporânea', link: 'Sem Link' },
            { formacao: 'Cursos Avançados em Teologia', curso: 'Ciências Das Religiões', link: 'Sem Link' },
            { formacao: 'Cursos Avançados em Teologia', curso: 'Antigo E Novo Testamento', link: 'Sem Link' },
            { formacao: 'Cursos Avançados em Teologia', curso: 'Teologia Sistemática', link: 'Sem Link' },
            { formacao: 'Cursos Avançados em Teologia', curso: 'História Da Igreja', link: 'Sem Link' },
            { formacao: 'Cursos Avançados em Teologia', curso: 'História De Israel', link: 'Sem Link' },
            { formacao: 'Cursos Avançados em Teologia', curso: 'dfsd', link: 'Sem Link' },
            { formacao: 'Cursos Técnicos', curso: 'Administração', link: 'https://colegiofaces.com/curso/administracao' },
            { formacao: 'Cursos Técnicos', curso: 'Secretaria ESCOLAR', link: 'https://colegiofaces.com/curso/secretariaescolar' },
            { formacao: 'Cursos Técnicos', curso: 'Informática (NOVO)', link: 'Sem Link' },
            { formacao: 'Cursos Técnicos', curso: 'Aquicultura', link: 'https://colegiofaces.com/curso/aquicultura/' },
            { formacao: 'Cursos Técnicos', curso: 'Podologia', link: 'https://colegiofaces.com/curso/podologia' },
            { formacao: 'Cursos Técnicos', curso: 'Mecatrônia (NOVO)', link: 'Sem Link' },
            { formacao: 'Cursos Técnicos', curso: 'Enfermagem', link: 'https://colegiofaces.com/curso/tecnicoemenfermagem' },
            { formacao: 'Cursos Técnicos', curso: 'Análises Clínicas (NOVO)', link: 'Sem Link' },
            { formacao: 'Cursos Técnicos', curso: 'Eletroeletrônica (NOVO)', link: 'Sem Link' },
            { formacao: 'Cursos Técnicos', curso: 'Estética', link: 'https://colegiofaces.com/curso/estetica' },
            { formacao: 'Cursos Técnicos', curso: 'Farmácia (NOVO)', link: 'Sem Link' },
            { formacao: 'Cursos Técnicos', curso: 'Automação INDUSTRIAL (NOVO)', link: 'Sem Link' },
            { formacao: 'Cursos Técnicos', curso: 'Radiologa', link: 'https://colegiofaces.com/curso/radiologia' },
            { formacao: 'Cursos Preparatórios', curso: 'Português Para Concursos', link: 'Sem Link' },
            { formacao: 'Cursos Preparatórios', curso: 'Direito Para Concursos', link: 'Sem Link' },
            { formacao: 'Cursos Preparatórios', curso: 'Pré-Enem 2023', link: 'Sem Link' },
            { formacao: 'Cursos Preparatórios', curso: 'Matemática Para Concursos Financeira Rac. Lógico)', link: 'Sem Link' },
            { formacao: 'Cursos Preparatórios', curso: 'Redação Para Concursos', link: 'Sem Link' },
            { formacao: 'Cursos Preparatórios', curso: 'Preparatório Para Polícia Militar', link: 'Sem Link' },
            { formacao: 'Especialização Técnicas - Pós Técnico', curso: 'Instrumentação Cirúrgica', link: 'Sem Link' },
            { formacao: 'Especialização Técnicas - Pós Técnico', curso: 'Rede De Computadores', link: 'Sem Link' },
            { formacao: 'Especialização Técnicas - Pós Técnico', curso: 'Programação De Jogos Digitais E Web', link: 'Sem Link' },
            { formacao: 'Especialização Técnicas - Pós Técnico', curso: 'Neonatal', link: 'Sem Link' },
            { formacao: 'Especialização Técnicas - Pós Técnico', curso: 'Robótica Industrial', link: 'Sem Link' },
            { formacao: 'Especialização Técnicas - Pós Técnico', curso: 'Desenvolvimento De Sistemas', link: 'Sem Link' },
            { formacao: 'Especialização Técnicas - Pós Técnico', curso: 'Enfermagem Do Trabalho', link: 'Sem Link' },
            { formacao: 'Especialização Técnicas - Pós Técnico', curso: 'Máquinas Navais', link: 'Sem Link' },
            { formacao: 'Especialização Técnicas - Pós Técnico', curso: 'Banco De Dados', link: 'Sem Link' },
            { formacao: 'Especialização Técnicas - Pós Técnico', curso: 'Urgência E Emergência', link: 'Sem Link' },
            { formacao: 'Capacitação Profissional', curso: 'Curso Prático Em Podologia', link: 'Sem Link' },
            { formacao: 'Capacitação Profissional', curso: 'Mamografia (+)', link: 'Sem Link' },
            { formacao: 'Capacitação Profissional', curso: 'Densitometria Óssea', link: 'Sem Link' },
            { formacao: 'Capacitação Profissional', curso: 'Administração De Medicamentos Punção Venosa', link: 'Sem Link' },
            { formacao: 'Capacitação Profissional', curso: 'Massagem Modeladora', link: 'Sem Link' },
            { formacao: 'Capacitação Profissional', curso: 'Ressonância Magnética', link: 'Sem Link' },
            { formacao: 'Capacitação Profissional', curso: 'Formação Em Missiologia E Plantação De Igrejas', link: 'Sem Link' },
            { formacao: 'Capacitação Profissional', curso: 'Drenagem Linfática', link: 'Sem Link' },
            { formacao: 'Capacitação Profissional', curso: 'Cuidador De Idosos', link: 'Sem Link' },
            { formacao: 'Capacitação Profissional', curso: 'Psicanálise Clínica', link: 'Sem Link' },
            { formacao: 'Capacitação Profissional', curso: 'Tomografia Computadorizada', link: 'Sem Link' },
            { formacao: 'Capacitação Profissional', curso: 'Atendimento E Reabilitação De Dependentes Químicos', link: 'Sem Link' },
        ]
    }),
    watch: {
        linkBox: {
            handler(novo,) {
                this.sortBy = novo
            },
            immediate: true
        },
        search(el) {
            this.pesquisarCurso(el)
            if (el == null) {
                this.items = this.items.map(x => x)
            }
        }
    },
    computed: {
        cursofiltrado() {
            return data => {
                let queryFiltroCurso = data.filter((nomeCurso) => {
                    if (this.search == '') {
                        return true
                    }
                    if (this.search == null || this.search == undefined) {
                        return true
                    }
                    if (nomeCurso.toLowerCase().normalize("NFD").replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, "").includes(this.search.toLowerCase().normalize("NFD").replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ""))) {
                        return true
                    } else {
                        return false
                    }
                })
                return queryFiltroCurso
            }
        },
        itensfiltrados() {
            return data => data.filter((formacao) => {
                if (this.sortBy === 'Todos os Cursos') {
                    return true
                } else {
                    return formacao.formacao === this.sortBy
                }
            })
        },
        numberOfPages() {
            return Math.ceil(this.items.length / this.itemsPerPage)
        },
        filteredKeys() {
            return this.keys.filter(key => key !== 'Graduação')
        },
    },
    methods: {
        redirecionarMaisInfo(formacao, curso) {
            this.linkCursos.forEach((e) => {

                if (e.formacao.trim().toLowerCase() == formacao.trim().toLowerCase() && e.curso.trim().toLowerCase() == curso.trim().toLowerCase()) {
                    if (e.link == 'Sem Link') {
                        this.sendMsmInscricaoTable(curso)
                    } else {
                        window.open(e.link, '_blank');
                    }
                }
            })
        },
        sendMsmInscricaoTable(curso) {
            this.msmInscricaoTable = `https://wa.me/${this.whatsAppTable}?text=Não apague essa mensagem: *Olá, gostaria de informações para inscrição no curso de ${curso}*`
            console.log('msmInscricaoTable', this.msmInscricaoTable)
            window.open(this.msmInscricaoTable, '_blank');
        },
        pesquisarCurso(strCurso) {
            if (strCurso === '' || strCurso == null || strCurso == undefined) {
                this.cursosEncontrados = {}
                return
            }
            let copyCursos = this.items.map((x) => x)
            copyCursos.forEach((ele) => {
                if (ele.formacao !== this.sortBy) {
                    if (this.sortBy !== 'Todos os Cursos') {
                        ele.cursos.filter(nomeCurso => {
                            if (nomeCurso.toLowerCase().normalize("NFD").replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, "").includes(strCurso.toLowerCase().normalize("NFD").replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ""))) {

                                this.cursosEncontrados = Object.assign({}, {
                                    curso: {
                                        formacao: ele.formacao,
                                        curso: nomeCurso
                                    }

                                })
                            }
                        })
                    }
                }
            })
        },
    },
    created() { },
    beforeMount() { },
    mounted() { }
};
</script>
  
<style>
/* .c-tabela-cursos tbody {
    display: flex;
    overflow: hidden;
    flex-flow: row wrap;
    justify-content: center;
} */

.c-lista {
    display: inline-flex;
    flex-flow: row wrap;
    align-items: center;
    align-content: center;
}

.c-lista .v-list-item {
    width: 250px !important;
    height: 120px;
    border: solid 1px #b4a6a6;
    border-radius: 5px;
    text-align: center;
    margin: 5px;
    flex-basis: auto;
    background: linear-gradient(to right, #072388, #2847b8, #072388e3, #193390, #072388d9);
}

.v-list-item {
    color: #ffffff !important;
    font-weight: 400;
    margin: 1px 5px;
    border-radius: 6px;
    border: solid 1px #c1c1c1;
    background: linear-gradient(to right, #072388, #2847b8, #072388e3, #193390, #072388d9);
}

.c-lista-curso {
    display: flex;
    justify-content: center;
}

.c-lista .v-list-item p {
    display: flex;
    padding: 0px !important;
    margin: 0px 0px !Important;
    justify-content: center;
}

.c-lista .v-list-item p button {
    margin: 0px 3px;
    font-size: 8.5px;
    font-weight: 900;
    letter-spacing: 1px;
}
</style>