<template>
    <v-footer color="transparent" padless>
        <!-- Footer Desktop -->
        <v-row v-if="$vuetify.breakpoint.mdAndUp" justify="center" no-gutters>
            <v-col cols="12" sm="12" class="c-footer-col text-center elevation-10 rounded-lg">

                <v-btn :href="linkInscricao" target="_blank" rounded
                    class="white--text c-btn-grandiente text-subtitle-1 px-10">Inscreva-se</v-btn>

                <v-btn href="https://goo.gl/maps/s2wbuLx6eeDdwKW67" target="_blank" rounded text large
                    class="secondary--text text-h5 px-10">Localização<v-icon right
                        class="ml-4 c-icon-footer">mdi-map-outline</v-icon></v-btn><span
                    class="text-h6 secondary--text mr-2">|</span>

                <v-btn href="https://www.facebook.com/faculdadecooperar" target="_blank" color="secondary" large text
                    fab><v-icon x-large>mdi-facebook</v-icon></v-btn>

                <v-btn href="https://www.instagram.com/facesbr" target="_blank" color="secondary" large text fab><v-icon
                        x-large>mdi-instagram</v-icon></v-btn>
                <v-img @click="sendLinkImg(linkEMac)" src="@/assets/emec.webp" lazy-src="@/assets/draft/emec-draft.webp"
                    min-width="100px" class="ml-auto u-cursor c-img-emec" conten width="80px" contain
                    fetchpriority="high" position="center"></v-img>
                <p class="c-img-emec-titulo white--text text-right pr-5">Consulte aqui o cadastro da instituição no
                    Sistema e-MEC</p>
            </v-col>
            <v-col class="py-4 text-center white--text" cols="12">
                <v-img @click="sendLinkImg('https://facesbr.com')" src="@/assets/logo-faculdade-faces-footer.webp"
                    class="u-cursor" conten height="55px" contain fetchpriority="high" position="center"></v-img>
            </v-col>
        </v-row>
        <!-- Footer Mobile -->
        <v-row v-if="$vuetify.breakpoint.smAndDown" justify="center" no-gutters>
            <v-col cols="12" sm="12" class="c-footer-col text-center elevation-10 rounded-lg py-6">

                <v-btn :href="linkInscricao" target="_blank" rounded
                    class="white--text c-btn-grandiente text-subtitle-1 px-10 mt-2">Inscreva-se</v-btn>

                <v-btn href="https://goo.gl/maps/s2wbuLx6eeDdwKW67" target="_blank" rounded text large
                    class="secondary--text text-h5 my-2 px-16">Localização<v-icon right
                        class="ml-4 c-icon-footer">mdi-map-outline</v-icon></v-btn>

                <v-btn href="https://www.facebook.com/faculdadecooperar" target="_blank" color="secondary" large text
                    fab><v-icon x-large>mdi-facebook</v-icon></v-btn>

                <v-btn href="https://www.instagram.com/facesbr" target="_blank" color="secondary" large text fab><v-icon
                        x-large>mdi-instagram</v-icon></v-btn>


            </v-col>

            <v-col class="py-4 text-center" cols="12">
                <v-img @click="sendLinkImg(linkEMac)" src="@/assets/emec.webp" lazy-src="@/assets/draft/emec-draft.webp"
                min-width="30px" class="u-cursor mt-4" conten height="100px" contain fetchpriority="high"
                position="center">
            </v-img>

            <p class="white--text text-uppercase mt-5 c-img-emec-titulo-mobile">Consulte aqui o cadastro da instituição no Sistema
                e-MEC
            </p>
            </v-col>

            <v-col class="py-4 text-center white--text" cols="12">
                <v-img @click="sendLinkImg('https://facesbr.com')" src="@/assets/logo-faculdade-faces-footer.webp"
                    class="u-cursor" conten height="55px" contain fetchpriority="high" position="center"></v-img>
            </v-col>
        </v-row>
    </v-footer>
</template>
  
<script>
export default {
    name: "FooterFaces",
    props: {
        linkInscricao: String
    },
    components: {},
    data: () => ({
        linkEMac: 'https://emec.mec.gov.br/emec/consulta-cadastro/detalhamento/d96957f455f6405d14c6542552b0f6eb/MjUzNDg=',
        links: [
            { localizacao: 'Localização', link: 'https://goo.gl/maps/s2wbuLx6eeDdwKW67' },
            { insta: 'mdi-instagram', link: 'https://www.instagram.com/facesbr' },
            { fb: 'mdi-facebook', link: 'https://www.facebook.com/faculdadecooperar' },
        ],
    }),
    computed: {},
    watch: {},
    methods: {
        sendLinkImg(link) {
            window.open(link, '_blank');
        }
    },
    created() { },
    beforeMount() { },
    mounted() { }
};
</script>
  
<style>
.c-img-emec-titulo-mobile {
    display: block;
    font-size: 12px;
    text-align: center;
    padding: 0px 49px!Important;
    font-family: 'layout_faces_heavy_book';
}
.c-img-emec-titulo {
    position: absolute;
    right: 51px;
    opacity: 0.8;
    width: 243px;
    text-transform: unset;
    bottom: -15px;
    font-size: 13px;
}

.c-img-emec {
    position: absolute;
    top: -42px;
    right: 70px;
}

.c-icon-footer {
    font-size: 35px !important;
}

.c-footer-col {
    border: solid rgb(255 255 255 / 24%) 1px;
}
</style>